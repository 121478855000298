@import "common/assets/variables";

.marker-component {
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .name {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }

    > .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > .icon {
        display: flex;
        align-items: center;

        > span {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        & + .icon {
          &:before {
            content: "";
            margin-left: 8px;
            margin-right: 8px;
            border-radius: 999px;
            width: 2px;
            height: 16px;
            background: #363636;
          }
        }
      }
    }
  }

  > .content {
    margin-top: 20px;

    > input {
      padding: 16px;
      width: 100%;
      background: #333333;
      border-radius: 8px;
      border: none;
      box-shadow: none;
      color: #fff;
      font-weight: 350;
      font-size: 16px;
      line-height: 19px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  > .settings {
    > .hue-picker {
      margin-top: 16px;
      width: 100% !important;

      > div {
        border-radius: 50px !important;

        > .hue-horizontal {
          border-radius: 50px !important;
        }
      }
    }

    > .radio-buttons {
      > .radio-box {
        margin-top: 16px;
        position: relative;
        input {
          display: none;

          &:checked {
            & + label {
              &:before {
                border-color: #88c6ff;
              }

              &:after {
                display: block;
              }
            }
          }
        }

        label {
          padding-left: 25px;
          font-weight: 350;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;

          &:before,
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 2px;
          }

          &:before {
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
          }

          &:after {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            top: 5px;
            left: 3px;
            background: #88c6ff;
            display: none;
          }
        }
      }
    }
  }

  & + .marker-component {
    margin-top: 24px;

    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: #363636;
      margin-bottom: 24px;
      display: block;
    }
  }
}
