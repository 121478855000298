@import "./variables";

@font-face {
  font-family: 'Kyiv*Type Sans';
  src: url("../fonts/KyivTypeSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kyiv*Type Sans';
  src: url("../fonts/KyivTypeSans-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kyiv*Type Sans';
  src: url("../fonts/KyivTypeSans-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Kyiv*Type Sans';
  src: url("../fonts/KyivTypeSans-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}


* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  font-family: 'Kyiv*Type Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #212121;
  
  #app {
    > header {
      display: flex;
      align-items: center;
      padding: 32px;
      background: #212121;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
}
