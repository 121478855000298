@import "common/assets/variables";

.save-component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: rgba(20, 20, 20, 0.49);

  &:not(.active) {
    display: flex;
  }

  &.active {
  }

  > .content {
    position: relative;
    max-width: 1040px;
    width: 100%;
    min-height: 555px;
    background: #2c2c2c;
    border-radius: 32px;
    display: flex;
    align-items: center;
    overflow: hidden;

    > .close {
      border: none;
      box-shadow: none;
      position: absolute;
      top: 25px;
      right: 25px;
      background: rgba(44, 44, 44, 0.6);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 9;
      
      > svg {
        display: block;
      }
    }

    > .markers {
      padding: 32px;
      width: 40%;

      > .title {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
      }

      > .list {
        > .marker {
          display: flex;
          gap: 8px;
          align-items: center;

          & + .marker {
            margin-top: 16px;
          }

          > .value {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            border-radius: 50%;
            flex-shrink: 0;
          }

          > .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }
        }

        > .empty {
          font-size: 16px;
          line-height: 18px;
          color: #ffffff;
        }
      }

      > .buttons {
        margin-top: 46px;
        display: flex;
        gap: 8px;

        > button {
          border: 2px solid #88c6ff;
          border-radius: 64px;
          box-shadow: none;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          padding: 9px 16px;
          min-width: 120px;
          cursor: pointer;

          &:not(.save) {
            color: #88c6ff;
            background: transparent;
          }

          &.save {
            background: #88c6ff;
            color: #000000;
          }
        }
      }
    }

    > .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 60%;
      background: #fff;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
