@import "common/assets/variables";

.editor-component {
  background: #2c2c2c;
  display: flex;
  height: 100%;

  > .editor-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;

    > .header {
      height: 61px;
      width: 100%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #171717;
    }

    > .content {
      background: #ececec;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;

      > canvas {
        background: #ececec;
        height: 580px;
        width: 760px;
      }
    }

    > .actions {
      padding: 32px;
      display: flex;
      justify-content: space-between;
      background: #ececec;

      > .left,
      > .right {
        gap: 16px;
        display: flex;

        &.disabled {
          * {
            pointer-events: none;
            filter: grayscale(1);
          }
        }

        > .button-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          background: #ffffff;
          border-radius: 50px;

          &.save {
            background: #212121;

            > button {
              background: #212121;
              color: #fff;

              &:hover {
                background: #484848;
              }
            }
          }

          &.rotate {
            > button {
              &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            .separator {
              width: 2px;
              height: 23px;
              background: #e1e1e1;
              border-radius: 999px;
            }
          }

          &.scale {
            > button {
              &:first-child {
                padding-right: 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              &:last-child {
                padding-left: 5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            > .value {
              margin: 0 5px;
            }
          }

          button {
            box-shadow: none;
            border: none;
            padding: 8px 16px;
            background: #ffffff;
            border-radius: 50px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #5e5e5e;
            cursor: pointer;
            height: 45px;
            display: flex;
            align-items: center;

            &:hover {
              background: #f3f3f3;
            }

            svg {
              display: block;
            }
          }
        }
      }

      > .right {
        button {
          min-width: 120px;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }

  > .markers-container {
    flex-shrink: 0;
    width: 450px;
    padding: 32px;
    overflow: auto;

    > .markers-list {
      > hr {
        margin: 24px 0;
        border: none;
        border-bottom: 2px solid #363636;
      }
    }

    > button {
      cursor: pointer;
      background: transparent;
      box-shadow: none;
      padding: 10px 24px;
      width: 100%;
      border: 2px solid #88c6ff;
      border-radius: 64px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #88c6ff;
    }
  }
}
