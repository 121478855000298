@import "common/assets/variables";

.drop-component {
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .drop-area-container {
    background: #262626;
    border: 4px dashed #3b3b3b;
    border-radius: 50px;
    max-width: 1200px;
    max-height: 600px;
    padding: 25px;
    width: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      border-color: #88C6FF;
    }

    > .drop-area-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 500px;
      width: 100%;

      > .title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 24px;
      }

      > .subtitle {
        font-style: normal;
        font-weight: 350;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 24px;
      }

      > svg {
        display: block;
        margin: 40px 0;
      }

      > .file-selector {
        position: relative;
        width: 176px;
        height: 45px;

        > input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          z-index: 5;
          opacity: 0;
          cursor: pointer;
        }

        > button {
          top: 0;
          position: absolute;
          padding: 0 24px;
          width: 100%;
          height: 100%;
          background: #88C6FF;
          border-radius: 64px;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          border: none;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
}
